body {
  font-family: 'Times New Roman', serif;
}

a {
  color: red;
}

.section {
  position: absolute;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  transition: .5s;
}

#Home {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

#About {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

/*HOME*/

#logoAbout {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 60vw;
}

.logo {
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

#homeLogo {
  width: 50vw;
  height: 40vh;
}

#intro {
  font-size: 14px;
  line-height: 20px;
  color: #333;
  text-align: center;
  padding-bottom: 10px
}

#nav {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  margin-top: 100px;
  width: 50%;
}

.linkNav {
  font-size: 40px;
  font-style: italic;
}

.linkNav a {
  color: black;
  text-decoration: none;
}

.linkNav span {
  color: white;
  -webkit-text-stroke: 1px black;
  transition: .5s;
  cursor: pointer;
}

.linkNav span:hover {
  color: red;
}

/*NAVBAR*/

#navBar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 13vh;
  transition: .5s;
  color: black;
  z-index: 3;
}

#navBarWork {
  position: absolute;
  left: 2%;
  top: 50%;
  transform: translateY(-100%);
}

#navBarHome {
  position: relative;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 20%;
  height: 100%;
}

#navBarAbout {
  position: absolute;
  right: 2%;
  top: 50%;
  transform: translateY(-100%);
}

#navBarBottom {
  display: none;
}

/*WORK*/

#grid {
  width: 100%;
  margin-top: 13vh;
  display: flex;
  flex-wrap: wrap;
}

.project {
  width: 20%;
  height: 300px;
  margin: 10px 2.5% 10px 2.5%;
  cursor: pointer;
}

.projectImageContainer {
  width: 100%;
  height: 50%;
  background-color: white;
}

.projectImage {
  position: relative;
  width: 100%;
  height: 100%;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  left: 50%;
  transform: translateX(-50%);
}

.projectTitle {
  margin-top: 10px;
  font-family: Helvetica;
  font-size: 18px;
  margin-bottom: 5px;
  text-align: center;
}

.projectDetails {
  margin: 0;
  font-size: 16px;
  text-align: center;
}

.projectType {
  margin-top: 3px;
  font-size: 15px;
  font-style: italic;
  text-align: center;
}

#update {
  text-align: center;
  font-style: italic;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

/*PROJECT PAGE*/
#projectPageImageSection {
  position: fixed;
  bottom: 0;
  height: 87%;
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

#projectPageImage {
  width: 80%;
  height: 50vh;
  background-image: url('');
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

#projectPageCredits {
  margin: 20px 0 10px 0;
  width: 50%;
  font-style: italic;
  font-size: 14px;
  text-align: center;
}

#projectPageTextSection {
  position: absolute;
  bottom: 0;
  right: 0;
  height: 87%;
  width: 50%;
  font-family: serif;
}

#projectPageTextTitle {
  margin: 20px 0 10px 0;
  width: 80%;
  font-weight: bold;
  font-size: 38px;
  line-height: 44px;
  font-family: Helvetica;
}

#projectPageTextAbstract {
  margin: 10px 0 10px 0;
  width: 90%;
  font-style: italic;
  font-size: 14px;
  line-height: 20px;
}

#projectPageText {
  margin: 0 0 10px 0;
  width: 75%;
  font-size: 14px;
  line-height: 20px;
  padding-bottom: 10vh;
}

#projectPageText img {
  width: 100%;
}

.imageDesc {
  text-align: center;
  font-style: italic;
  margin-top: 0;
}

#projectPageTextLink {
  margin: 0;
  color: red;
  font-size: 14px;
  line-height: 20px;
  text-decoration: underline;
  cursor: pointer;
  font-family: sans-serif;
}

/*ABOUT*/

#photo {
  margin-top: 10vh;
  width: 30vw;
  height: 50vh;
  background-image: url('');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

#aboutText {
  margin-top: 5vh;
  width: 80vw;
  font-size: 14px;
  text-align: center;
}

#credits {
  margin-top: 5vh;
  font-style: italic;
  font-size: 12px;
}

#credits a {
  color: red;
}

/*MOBILE*/

@media only screen and (max-width: 600px) {

  #Home {
    display: block;
  }


  #logoAbout {
    position: absolute;
    top: 5%;
    width: auto;
  }

  #homeLogo {
    width: 100vw;
    height: 30vh;
  }

  #intro {
    width: 80vw;
    margin-bottom: 10px;
    padding-bottom: 0;
  }

  #nav {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 100px auto 0 auto;
    width: 70%;
    height: 20%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%);
  }

  .linkNav {
    margin: 5% 0 5% 0;
  }

  /* Mobile Navbar*/

  #navBarWork {
    display: none;
  }

  #navBarHome {
    position: relative;
    left: 50%;
    top: auto;
    transform: translateX(-50%);
    width: 50%;
    height: 100%;
  }

  #navBarAbout {
    display: none;
  }

  #navBarBottom {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100vw;
    height: 8vh;
    position: fixed;
    bottom: 0;
    left: 0;
    background-color: white;
    z-index: 3;
    transition: .5s;
  }

  #navBarBottom a {
    color: white;
    text-decoration: none;
    -webkit-text-stroke: 1px black;
  }

  .linkNavBottom {
    font-size: 40px;
    font-style: italic;
    text-align: center;
    width: 50%;
    color: white;
    -webkit-text-stroke: 1px black;
  }

  /*MOBILE WORK*/

  /*WORK*/

  #grid {
    padding-bottom: 13vh;
  }

  .project {
    width: 100%;
    height: 70vh;
    margin: 0;
  }

  .projectImageContainer {
    width: 100%;
    height: 60%;
    background-color: white;
  }

  .projectImage {
    position: static;
    margin: 0 auto 0 auto;
    width: 100%;
    height: 100%;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    transform: none;
  }

  .projectTitle {
    font-family: Helvetica;
    font-size: 25px;
    padding: 0 5% 0 5%;
  }

  .projectDetails {
    padding: 3% 5% 0 5%;
    font-size: 20px;
  }

  .projectType {
    font-style: italic;
    padding: 2% 5% 0 5%;
    font-size: 20px;
  }

  /*PROJECT PAGE*/

  #projectPageImageSection {
    position: static;
    height: 30%;
    width: 100%;
    margin: 13vh auto 0 auto;
    padding: 0;
  }

  #projectPageImage {
    margin: 0;
    width: 100vw;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
  }

  #projectPageTextSection {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: static;
    height: auto;
    width: 100%;
    padding-left: 0;
    font-family: serif;
    text-align: center;
    padding-bottom: 20%;
  }

  #projectPageTextTitle {
    width: 100%;
  }

  #projectPageTextAbstract {
    margin: 10px 0 10px 0;
    width: 80%;
  }

  #projectPageText {
    margin: 0 0 10px 0;
    width: 90%;
  }

  #projectPageTextLink {
    margin: 0 0 0 0;
  }

  /*ABOUT*/

  #About {
    height: 115vh;
  }

  #photo {
    margin-top: 10vh;
    width: 50vw;
    height: 30vh;
  }

  #aboutText {
    text-align: center;
    width: 90vw;
  }

  #credits {
    margin-top: 0;
  }
}